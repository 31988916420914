import { Link } from "gatsby"
import React from "react"
import bg from "../../images/img/bg-alpen.jpg"
import "./index.scss"
import VizSensor from "react-visibility-sensor"

class DeliveryBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  render() {
    return (
      <VizSensor
        onChange={isVisible => {
          this.setState({
            visible: isVisible,
          })
        }}
        active={!this.state.visible}
      >
        <section
          className={this.state.visible ? "delivery visible" : "delivery"}
        >
          <section className="delivery__image">
            <img src={bg}></img>
          </section>
          <section className="delivery__info">
            <section className="delivery__info__head">
              <h3>Ja wir liefern.</h3>
            </section>
            <section className="delivery__info__text">
              <p>
                Sie können uns nicht persönlich besuchen? Gar kein Problem! Wir
                liefern kostengünstig von Uderns bis Wiesing. Dabei spielt es
                gar keine Rolle ob Sie eine Pizza bevorzugen oder ein
                Spezialgericht aus unserer Bosphorus Küche.
              </p>
              <br />
              <p>
                Wir bieten auch eine Take-Away Option an. Einfach Bestellung
                abgeben und beim Vorbeifahren abholen.
              </p>
              <br />
              <p>Orientalische Küche zum Genießen. Egal wo.</p>
            </section>
            <section className="delivery__info__action">
              <a href="https://bosphorus-lieferung.at/" target="_blank">
                Jetzt bestellen
              </a>
            </section>
          </section>
        </section>
      </VizSensor>
    )
  }
}

export default DeliveryBlock
