import React from "react"
import "./index.scss"
import map from "../../images/img/map.jpg"

class MapBlock extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <section className="map">
        <section className="map__image">
          <a
            href="https://www.google.com/maps/dir//bosphorus+restaurant+schlitters/@47.378352,11.8065903,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4777d7f0aa173c49:0x8e361b02dac8b20!2m2!1d11.8416096!2d47.3782992"
            target="_blank"
          >
            <img src={map}></img>
          </a>
        </section>
        <section className="map__info">
          <h3>Hier findest du uns.</h3>
          <div className="map__info__contact">
            <h4>Kontaktdaten:</h4>
            <ul>
              <a
                href="https://www.google.com/maps/dir//bosphorus+restaurant+schlitters/@47.378352,11.8065903,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4777d7f0aa173c49:0x8e361b02dac8b20!2m2!1d11.8416096!2d47.3782992"
                target="_blank"
              >
                <li>Haus 48a, A-6262 Schlitters</li>
              </a>
              <a href="tel:+43528872299">
                <li>+43 (0) 5288 72 299</li>
              </a>
              <a href="tel:+436766705016">
                <li>+43 (0) 676 670 50 16</li>
              </a>
            </ul>
          </div>
        </section>
      </section>
    )
  }
}

export default MapBlock
