import React from "react"
import "./index.scss"

class FeedbackBlock extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="feedback">
        <h3>Das sagen unsere Kunden.</h3>
        <section className="feedback__list">
          <ul>
            <li>
              <a href="https://goo.gl/maps/bK2mfNUK5Vmn82jM8" target="_blank">
                <h4>Karina Mayr</h4>
                <p>
                  Wir haben zum 1. mal bestellt und waren total zufrieden! Pizza
                  war super lecker und der Lieferant war sehr nett und
                  freundlich :) Bestellen auf jedenfall wieder. Nur zum
                  empfehlen!
                </p>
                <p>- auf Google</p>
              </a>
            </li>
            <li>
              <a href="https://goo.gl/maps/MNuhtcZkBtWUx5B37" target="_blank">
                <h4>Alexander Farthofer</h4>
                <p>
                  Waren schon 2 Mal da war immer alles Top, Essen, Bedienung,
                  Ambiente alles sehr zu empfehlen. Und auch preislich wirklich
                  günstig.
                </p>
                <p>- auf Google</p>
              </a>
            </li>
            <li>
              <a href="https://goo.gl/maps/7SgGrUbVX3g5biXh8" target="_blank">
                <h4>Ergün Isilak</h4>
                <p>
                  Köfteteller sehr lecker, mit künefe Abschluss, angenehme
                  atmosphäre, nette Bedienung, komme gerne wieder
                </p>
                <p>- auf Google</p>
              </a>
            </li>
          </ul>
        </section>
      </section>
    )
  }
}

export default FeedbackBlock
