import React from "react"
import Layout from "../components/Layout/"
import SEO from "../components/seo"

import FullScreenBanner from "../components/FullScreenBanner"
import CategoryList from "../components/CategoryList"
import DeliveryBlock from "../components/DeliveryBlock"
import FeedbackBlock from "../components/FeedbackBlock"
import OpeningHoursBlock from "../components/OpeningHoursBlock"
import MapBlock from "../components/MapBlock"
import { graphql } from 'gatsby'
import Social from "../components/Social"

const IndexPage = ({data})  => {
  const categories = data.allContentfulKategorie.nodes;
  return (
    <Layout>
      <Social />
        <SEO title="Startseite" />
        <FullScreenBanner />
        <CategoryList categories={categories}/>
        <DeliveryBlock />
        <FeedbackBlock />
        <MapBlock />
        <OpeningHoursBlock />
    </Layout>
  )
}

export const query = graphql`
{
  allContentfulKategorie(
    filter: {aktiv: {eq: true}}
    sort: {order: ASC, fields: reihenfolge}
    ) {
    nodes {
      ueberschrift
      bild {
        file {
          url
        }
      }
    }
  }
}
`

export default IndexPage;
