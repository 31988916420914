import React, { Component } from "react"
import { Link } from "gatsby"
import "./index.scss"

class FullScreenBanner extends Component {
  render() {
    return (
      <section className="fullscreen">
        <section className="fullscreen__textarea">
          <h2>Die Brücke zwischen 2 Kulturen</h2>
          <p>
           Lass dir unsere köstlichen Rezepte mit frischen Zutaten zu deiner Haustür liefern. <br></br>
           Ab jetzt kannst du deine Bestellung online aufgeben und zu Hause genießen.
           <span>INFO: </span> Liebe Gäste wir haben vom 03.06.24 - 18.06.24 Betriebsurlaub. Ab dem 19.06.24 sind wir wie gewohng für Sie da.
            {/* Bosphorus ist ein im Zillertal geborenes Konzept, das 2019 ins Leben
            gerufen wurde. <br />
            <br />
            Es entspringt dem Traum, die authentisch orientalische Küche und
            ihre reiche Geschichte, kulturell bekannt zu machen. */}
          </p>
        </section>
        <section className="fullscreen__actionarea">
          <Link to="https://bosphorus-lieferung.at">Jetzt Bestellen</Link>
        </section>
      </section>
    )
  }
}

export default FullScreenBanner
