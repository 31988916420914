import { Link } from "gatsby"
import React from "react"
import "./index.scss"
import VizSensor from "react-visibility-sensor"

class CategoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  render() {
    console.log(this.props);
    return (
      <section className="categorylist">
        {/* <VizSensor
          onChange={isVisible => {
            if (!this.state.visible && isVisible) {
              setTimeout(() => {
                this.setState({
                  visible: true,
                })
              }, 750)
            }
          }}
          active={!this.state.visible}
          scrollCheck={true}
        > */}
          <section className="categorylist__header">
            <h2>Unsere Spezialitäten.</h2>
          </section>
        {/* </VizSensor> */}
        <section className="categorylist__list">
          <ul className="visible">
          {this.props.categories.map((item, index) => {
            return <li key={index}>
              <Link to={`/speisekarte#${item.ueberschrift.toLowerCase()}`}>
                <img src={item.bild.file.url}></img>
                <h3>{item.ueberschrift}</h3>
              </Link>
              </li>
          })}
          </ul>
        </section>
      </section>
    )
  }
}

export default CategoryList
